<nav class="navbar navbar-expand-lg navbar-dark" style="background-color: teal;">
    <div class="container-fluid">
        <!-- <a class="navbar-brand" href="#">Navbar</a> -->
        <div class="logo-container">
            <a href="#" class="logo-link">
            <img width="220" height="70" alt="" class="logo-img" src="../../../assets/Fotos/logo.png">
            </a>
        </div>
        <div class="ms-2">
            <form class="d-flex">
                <input class="form-control me-2" type="search" (click)="goToProductos()" (keydown)="onSearchInput($event.target.value)" placeholder="¿Qué producto buscas?" aria-label="Search">
                <!-- <button class="btn btn-outline-success" routerLink="/productos" type="submit">Buscar</button> -->
            </form>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link letrablanca" aria-current="page" routerLinkActive="active" routerLink="/home">Inicio</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle letrablanca" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Productos
                    </a>
                    <ul class="dropdown-menu mx-auto m-1" aria-labelledby="navbarDropdown" style="position: absolute;">
                        <li *ngFor="let categoria of categorias" class="dropdown-item">
                            <div class="row pointer" style="margin-right: 25px" (click)="setCategoria(categoria.id)">
                                <div class="col-3">
                                    <img src="{{ categoria.foto }}" class="rounded-circle" style="width: 20px; height: 20px;" alt="">
                                </div>
                                <div class="col-9" style="text-align: left; padding-left: 0; margin-left: 0">
                                    <a class="">{{ categoria.nombre }}</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle letrablanca" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Catálogos
                    </a>
                    <ul class="dropdown-menu mx-auto m-1" aria-labelledby="navbarDropdown" style="position: absolute;">
                        <li *ngFor="let catalogo of catalogos" class="dropdown-item">
                            <div class="row pointer" style="margin-right: 25px" (click)="downloadCatalogue(catalogo.url)">
                                <div class="col-4">
                                    <img src="{{ catalogo.foto }}" class="rounded-circle" style="width: 20px; height: 20px;" alt="">
                                </div>
                               
                                <div class="col-8" style="text-align: left; padding-left: 0; margin-left: 1">
                                    <a class="">{{ catalogo.nombre }}</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link letrablanca" aria-current="page" routerLinkActive="active" routerLink="/locaciones">¿Dónde comprar?</a>
                </li>
                <!-- <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link letrablanca" aria-current="page" routerLinkActive="active" routerLink="/videos">Videos</a>
                </li> -->
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link letrablanca" aria-current="page" routerLinkActive="active" routerLink="/about">¿Quiénes Somos?</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li> -->
            </ul>


            <ul style="padding: 10px;">
                <span class="letrablanca">Contáctanos: </span>
                <a href="https://www.facebook.com/distribuidorajv.sc" class="bordeIcon" target="_blank">
                    <img src="../../../assets/icons/facebook.png" style="width: 40px; height: 40px; margin: 5px;">
                </a>
                <!-- <a href="https://wa.me/59171635250" target="_blank">
                    <img src="../../../assets/icons/whatsapp.png" class="bordeIcon" style="width: 40px; height: 40px; margin: 5px;">
                </a> -->
            </ul>

        </div>
    </div>
</nav>