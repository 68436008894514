import { Component, OnInit, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';



@Component({
  selector: 'app-locations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.css'
})
export class LocationsComponent implements OnInit {
  public ciudades = [
    { 
      nombre: 'Santa Cruz',
      bandera: '../../../assets/Fotos/Ciudades/SantaCruz.png',
      locaciones: [
        {
          nombre: 'Mercado Alto San Pedro',
          ubicacion: 'https://maps.app.goo.gl/DAad1JfRahgvbTiH7'
        },
        {
          nombre: 'Mercado Ferreteros Brasil',
          ubicacion: 'https://maps.app.goo.gl/VFS4J5rz2Q8ozTrQ7'
        }
      ] 
    },
    { 
      nombre: 'La Paz', 
      bandera: '../../../assets/Fotos/Ciudades/LaPaz.png',
      locaciones: [] 
    },
    { 
      nombre: 'Cochabamba', 
      bandera: '../../../assets/Fotos/Ciudades/Cochabamba.jpg',
      locaciones: [
        {
          nombre: 'Mercado La Pampa',
          ubicacion: 'https://maps.app.goo.gl/pqPs8GyciT17ujHc7'
        },
        {
          nombre: 'Mercado Quillacollo',
          ubicacion: 'https://maps.app.goo.gl/6HwKPKwdBAdtXJQy7'
        },
        
      ] 
    },
    { 
      nombre: 'Trinidad', 
      bandera: '../../../assets/Fotos/Ciudades/beni.jpg',
      locaciones: [] 
    },
    { 
      nombre: 'Tarija', 
      bandera: '',
      locaciones: [] 
    },
    { 
      nombre: 'Oruro', 
      bandera: '',
      locaciones: [] 
    },
  ];
  constructor(){
    /* this.ciudades = [
      { nombre: 'Ciudad 1', locaciones: ['Locación 1', 'Locación 2', 'Locación 3'] },
      { nombre: 'Ciudad 2', locaciones: ['Locación A', 'Locación B', 'Locación C'] },
      { nombre: 'Ciudad 3', locaciones: ['Locación X', 'Locación Y', 'Locación Z'] },
    ]; */
    console.log(this.ciudades);
  }
  ngOnInit(): void {
    /* this.ciudades = [
      { nombre: 'Ciudad 1', locaciones: ['Locación 1', 'Locación 2', 'Locación 3'] },
      { nombre: 'Ciudad 2', locaciones: ['Locación A', 'Locación B', 'Locación C'] },
      { nombre: 'Ciudad 3', locaciones: ['Locación X', 'Locación Y', 'Locación Z'] },
    ]; */
    console.log(this.ciudades);

  }
}
