import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompartidosService } from '../../services/compartidos.service';


@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  producto: any;

  constructor(
    public dialog: MatDialogRef<DetalleComponent>,
    public compartidos: CompartidosService
  ) {
    console.log(this.compartidos.getProducto());
    this.producto = this.compartidos.getProducto();
  }

  ngOnInit(): void {
  }

  close() {
    this.dialog.close();
  }

}
