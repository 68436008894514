import { Component, OnInit, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { CompartidosService, Producto } from 'src/app/services/compartidos.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-detalle-producto',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './detalle-producto.component.html',
  styleUrl: './detalle-producto.component.css'
})
/* @NgModule({
...
}) */
export class DetalleProductoComponent implements OnInit {
  public product: Producto;
  public relacionados: Producto[];
  constructor(
    private compartidos_service: CompartidosService,
    private route: ActivatedRoute,
    public router: Router
  ){
    /* debugger;
    this.product = this.compartidos_service.getProducto();
    if(Object.keys(this.product).length === 0){
      const paramProductName = this.route.snapshot.paramMap.get('producto');
      this.product = this.compartidos_service.getProductoByUrlName(paramProductName);
    }
    this.relacionados = this.compartidos_service.getProductosRelacionadosByProducto(this.product); */
  }
  ngOnInit(): void {
    debugger;
    this.product = this.compartidos_service.getProducto();
    if(Object.keys(this.product).length === 0){
      const paramProductName = this.route.snapshot.paramMap.get('producto');
      this.product = this.compartidos_service.getProductoByUrlName(paramProductName);
    }
    this.relacionados = this.compartidos_service.getProductosRelacionadosByProducto(this.product);
  }

  buyThis(){
    window.open('https://wa.me/59171635250?text=Hola,+estoy+interesado+en+el+producto+'+this.product.nombre+'+me+proporciona+mas+información+por+favor','_blank');
  }

  openDialog(producto){
    this.compartidos_service.setProducto(producto);
    let nombreRuta = this.compartidos_service.createRouteFromName(producto.nombre);
    this.router.navigate(["producto", nombreRuta]);
    this.product = producto;
    this.relacionados = this.compartidos_service.getProductosRelacionadosByProducto(this.product);
    window.scrollTo(0, 0);
  }
}
