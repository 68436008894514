<!--     <ng-image-slider class="d-flex" [animationSpeed]="1" [imagePopup]="false" [infinite]="true" [images]="imagenes" [imageSize]="{width: '100%', height: '80%', space: 1}" [infinite]="true" [autoSlide]="{interval: 4, stopOnHover: false}"></ng-image-slider>
 -->
<!-- portada -->
<!-- <div class="centrar" style="margin-bottom: 30px;">
    <img src="../../assets/Fotos/Banners-menu/banner1.png" alt="" style="width: 100%; height: 100%;">
</div> -->
<h1 style="display: none;">ImportadoraJV</h1>
<h1 style="display: none;">Importadora JV</h1>
<h1 style="display: none;">Impermeabilizante</h1>
<h1 style="display: none;">Membrana asfaltica</h1>
<h1 style="display: none;">Goteras</h1>
<h1 style="display: none;">Bolivia</h1>
<h1 style="display: none;">Viapol</h1>
<h1 style="display: none;">Viqua</h1>
<h1 style="display: none;">Plasbohn</h1>

<div class="container">
    <div id="carouselBannerFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="3000">
            <img src="../../assets/Fotos/Banners-menu/banner-1.png" class="d-block max-width-100" alt="...">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="../../assets/Fotos/Banners-menu/banner-2.png" class="d-block max-width-100" alt="...">
          </div>
          <div class="carousel-item" data-bs-interval="3000">
            <img src="../../assets/Fotos/Banners-menu/banner-3.png" class="d-block max-width-100" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselBannerFade" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselBannerFade" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>
</div>

<br>
<hr>
<br>

<div class="container">
    <h3><b>Productos Destacados</b></h3>
    <!-- <div id="carouselDestacadosFade" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active"> -->

    <div class="row">
        <div *ngFor="let producto of destacados" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated">
            <div class="card m-1 pointer mx-auto" (click)="openDialog(producto)" data-bs-toggle="tooltip" data-bs-placement="right" title="" style="width: 18rem; height: 16rem;">
                <img src="{{ producto.foto }}" style="width: 150px; height: 150px;" class="card-img-top mx-auto" alt="...">
                <div class="card-body">
                    <h5 class="card-title letraviqua centrarLetras"><b>{{ producto.nombre }}</b></h5>
                    <!-- <p class="card-text"></p>
                        <a href="" routerLink="/productos" class="btn btn-primary">Go somewhere</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
    
    <br>
    <hr>
    <br>

<div class="container">
    <h3 style=""><b>¿Quienes somos?</b></h3>
    <div class="container fadeIn m-1" style="margin-bottom: 30px;">
        <div class="row m-1" style="font-size: 20px; letter-spacing: 0.5px; line-height: 35px; padding: 10px">
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <img src="../../../assets/Fotos/about1.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px;">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <div class="">
                    <span class="">
                        Somos una empresa boliviana con sede en Santa Cruz de la Sierra, fundada en el año 2008. 
                        Nos especializamos en la importación y distribución de productos principalmente brasileños de la más alta calidad. 
                        La misión como empresa, nos compromete a proporcionar a nuestros clientes una oferta eficiente, garantizando productos de excelencia a precios accesibles. 
                        Aspiramos a extender esta calidad a lo largo de todo el territorio boliviano, consolidándonos como referentes en la entrega de productos con una excelente calidad y precio.
                    </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <h2 style="color: teal;">Misión</h2>
                <div class="">
                    <span class="">
                        "Aportar a la sociedad boliviana productos de alta calidad, a un precio accesible, 
                        ofreciendo la distribucion de manera rápida, oportuna y responsable"
                    </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <img src="../../../assets/Fotos/about2.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <img src="../../../assets/Fotos/about3.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <h2 style="color: teal;">Visión</h2>
                <div class="">
                    <span class="">
                        "Ser una empresa de alcance nacional, importando productos y soluciones oportunas, 
                        con la mejor relación calidad-precio"
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- <h1><b>Categorías</b></h1>
    <div class="row">
        <div class="col animated fadeIn pe-auto centrar" *ngFor="let categoria of categorias; let i = index">
            <div class="card m-1 pointer borderFade centrar" style="width: 18rem;" (click)="setCategoria(categoria.id)">
                <img src="{{ categoria.foto }}" style="width: 200px; height: 200px;" class="card-img-top mx-auto" alt="...">
                <div class="card-body">
                    <h3 class="card-title letraviqua">{{ categoria.nombre }}</h3>
                    <p class="card-text"></p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br> -->
    </div>

    <br>
    <hr>
    <br>

<div  class="container">
    <div id="carouselMarcasFade" class="carousel slide carousel-fade mt-2" data-bs-ride="carousel">
        <h3 class="my-2"><b>Marcas</b></h3>
        <div class="row carousel-inner m-2 my-5">
            <div *ngFor="let marca of marcas" style="padding-right: 2px; padding-left: 2px;" class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 animated fadeIn mx-auto">
                    <img src="{{ marca.foto }}" class="card-img-top" alt="...">
            </div>
        </div>
    </div>
</div>