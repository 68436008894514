import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompartidosService, Producto } from '../../services/compartidos.service';
import { DetalleComponent } from '../../components/detalle/detalle.component';
import { Observable } from 'rxjs';
import { Router, RouterModule } from '@angular/router';

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  public productos:  Producto[];
  public idCategoria;

  constructor(
    private compartidos_service: CompartidosService,
    public dialog: MatDialog,
    public router: Router
  ) {

  }

  ngOnInit(): void {
    this.idCategoria = this.compartidos_service.getIdCategoria();
    if(this.idCategoria === undefined){
      this.productos = this.compartidos_service.getProductos();
    } else {
      this.compartidos_service.filtrarByIdCategoria(this.idCategoria);
    }
      /* this.compartidos_service.changeFiltrados.subscribe(value => {
        this.productos = value;
        console.log(this.productos); 
      }); */
      //works only from home
      this.compartidos_service.changeFiltrados.subscribe(value => {
        this.productos = value;
      });
  }

  public openDialog(producto) {
    this.compartidos_service.setProducto(producto);
    this.dialog.open(DetalleComponent, {
      width: '900px',
      panelClass:'custom-dialog',
      hasBackdrop: true,
      closeOnNavigation: true
      // backdropClass: 'bdrop'
    });
  }

  

  public openPage(producto) {
    this.compartidos_service.setProducto(producto);
    let nombreRuta = this.compartidos_service.createRouteFromName(producto.nombre);
    this.router.navigate(["producto", nombreRuta]);
    /* this.dialog.open(DetalleComponent, {
      width: '900px',
      panelClass:'custom-dialog',
      hasBackdrop: true,
      closeOnNavigation: true
      // backdropClass: 'bdrop'
    }); */
  }

}

/* @Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.component.html',
})
export class DialogDataExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
} */
