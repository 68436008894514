import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CompartidosService } from '../../services/compartidos.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {

    public searchText = '';
    public categorias;
    public catalogos;

    constructor(
        private compartidos_service: CompartidosService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        
    }

    ngOnInit(): void {
        this.categorias = this.compartidos_service.getCategorias();
        this.catalogos = this.compartidos_service.getCatalogues();
    }

    setCategoria(id){
        console.log(this.router.url);
        this.compartidos_service.setIdCategoria(id);
        //window.location.reload();
        //if( this.router.url+'' === '/productos' ){
        this.compartidos_service.filtrarByIdCategoria(id);
        //} else {
        this.router.navigate(['/productos']);
        //}
        //console.log('wtfs');
    }
    downloadCatalogue(url){
        console.log(url);
        window.open(url, '_blank');
    }

    onSearchInput(text) {
        /* for (let categoria of this.categoriasLocales) {
          document.getElementById('cat_' + categoria.id).style.color = 'white';
        } */
        //this.searchList = [];
        this.compartidos_service.filtrarByNombre(text);
    }

    goToProductos(){
        this.router.navigate(['/productos']);
    }
}