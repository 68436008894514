import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-body',
    templateUrl: './body.component.html'
})

export class BodyComponent {
  @Input() imagenes: object;
    
}