<!-- <div class="centrar" style="margin-bottom: 30px;" *ngIf="1">
    <img src="../../assets/Fotos/Banners-menu/banner1.png" alt="" style="width: 100%; height: 100%;">
</div> -->
<div class="container">
    <div class="row m-1">
        <div *ngFor="let producto of productos" style="padding-right: 2px; padding-left: 2px;" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated fadeIn centrar">
            <div class="card m-1 pointer centrar" (click)="openPage(producto)" data-bs-toggle="tooltip" data-bs-placement="right" title="" style="width: 18rem; height: 16rem;">
                <img src="{{ producto.foto }}" style="width: 150px; height: 150px;" class="card-img-top mx-auto" alt="...">
                <div class="card-body">
                    <h5 class="card-title letraviqua "><b>{{ producto.nombre }}</b></h5>
                    <!-- <p class="card-text"></p>
                        <a href="" routerLink="/productos" class="btn btn-primary">Go somewhere</a> -->
                </div>
            </div>
        </div>
    </div>
</div>