import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompartidosService {
  //categorias tiene id
  //categorias tiene id
  //productos tienen index como id
  private CATEGORIAS = [
    {
      nombre: 'Impermeabilizantes',
      foto: '../../assets/Fotos/Productos/impermeabilizantes.png',
      id: '1'
    },
    {
      nombre: 'Cocina',
      foto: '../../assets/Fotos/Productos/grifos.jpg',
      id: '2'
    },
    {
      nombre: 'Baño',
      foto: '../../assets/Fotos/Productos/baño.jpg',
      id: '8'
    },
    {
      nombre: 'Lavandería',
      foto: '../../assets/Fotos/Productos/lavanderia.jpg',
      id: '9'
    },
    {
      nombre: 'Jardin',
      foto: '../../assets/Fotos/Productos/jardin.png',
      id: '4'
    },
    {
      nombre: 'Riego',
      foto: '../../assets/Fotos/Productos/riego.jpeg',
      id: '5'
    },
    {
      nombre: 'Filtros',
      foto: '../../assets/Fotos/Productos/filtros.jpg',
      id: '7'
    }
  ];

  private CATEGORIASa = [
    {
      nombre: 'Impermeabilizantes',
      foto: '../../assets/Fotos/Productos/impermeabilizantes.png',
      id: '1'
    },
    {
      nombre: 'Grifos',
      foto: '../../assets/Fotos/Productos/grifos.jpg',
      id: '2'
    },
    {
      nombre: 'Accesorios',
      foto: '../../assets/Fotos/Productos/accesorios.jpg',
      id: '3'
    },
    {
      nombre: 'Jardin',
      foto: '../../assets/Fotos/Productos/jardin.png',
      id: '4'
    },
    {
      nombre: 'Riego',
      foto: '../../assets/Fotos/Productos/riego.jpeg',
      id: '5'
    },
    {
      nombre: 'Mangueras',
      foto: '../../assets/Fotos/Productos/mangueras.jpg',
      id: '6'
    },
    {
      nombre: 'Filtros',
      foto: '../../assets/Fotos/Productos/filtros.jpg',
      id: '7'
    }
  ];

  private PRODUCTOS: Producto[] = [
    {
      nombre: 'Hidropistola 7 Funciones',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-1.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '1',
      descripcion: '',
    },
    {
      nombre: 'Hidropistola Ajustable 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-2.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '2',
      descripcion: ''
    },
    {
      nombre: 'Hidropistola 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-3.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '3',
      descripcion: ''
    },
    {
      nombre: 'Puntero 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-4.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '4',
      descripcion: ''
    },
    {
      nombre: 'Acople Rápido 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-5.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '5',
      descripcion: ''
    },
    {
      nombre: 'Adaptador 3/4 y 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-6.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '6',
      descripcion: ''
    },
    {
      nombre: 'Union de Manguera 1/2',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-7.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '7',
      descripcion: ''
    },
    {
      nombre: 'Top Flex Verde con Puntero',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-8.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '8',
      descripcion: ''
    },
    {
      nombre: 'Grifo de Mesa Blanc Pico Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_1.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '9',
      descripcion: '<b>Accionamiento:</b> Mecanismo de  1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½”.'

    },
    {
      nombre: 'Grifo de pared Blanc Pico Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_28.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '86',
      descripcion: '<b>Accionamiento:</b> Mecanismo de  1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½” y ¾”.'

    },
    {
      nombre: 'Grifo de Pared pico Alto Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_2.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '10',
      descripcion: '<b>Accionamiento:</b> Mecanismo de cierre cerámico de 1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½” y ¾”. <br> <b>Opciones de color:</b> blanco y cromo.'
    },
    {
      nombre: 'Grifo de Pared Pico Largo Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_3.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '11',
      descripcion: '<b>Accionamiento:</b> Mecanismo de cierre cerámico de 1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½” y ¾”. <br> <b>Opciones de color:</b> blanco y cromo.'
    },
    {
      nombre: 'Grifo de Pared Pico Horizontal Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_19.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '12',
      descripcion: '<b>Accionamiento:</b> Mecanismo de cierre cerámico de 1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½” y ¾”. <br> <b>Opciones de color:</b> blanco y cromo.'
    },
    {
      nombre: 'Grifo de Mesa con Pico Móvil para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_25.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '83',
      descripcion: '<b>Accionamiento:</b> Mecanismo de cierre cerámico de 1/4 de vuelta, asegurando un cierre rápido y perfecto con menor esfuerzo. <br> <b>Aireador:</b> Garantizando un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un chorro suave. <br> <b>Pico giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del lugar, facilitando su manipulación diaria. <br> <b>Conexión de entrada:</b> de ½” y ¾”. <br> <b>Opciones de color:</b> blanco y cromo.'
    },
    {
      nombre: 'Grifo para Lavamanos Pico Alto Móvil',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_4.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '13',
      descripcion: '<b>Funcionamiento:</b> Accionamiento giratorio con 3 vedas para garantizar una perfecta estanqueidad y fácil manejo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 50%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'
    },
    {
      nombre: 'Grifo para Lavamanos',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_21.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '26',
      descripcion: '<b>Funcionamiento:</b> Accionamiento giratorio con 3 vedas para garantizar una perfecta estanqueidad y fácil manejo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 50%, evita salpicaduras y proporciona comodidad para las manos con chorro suave.'
    },
    {
      nombre: 'Grifo de Lavanderia Doble para Lavadora',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_17.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '23',
      descripcion: ''
    },
    {
      nombre: 'Grifo de Lavanderia de 10cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_26.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '84',
      descripcion: ''
    },
    {
      nombre: 'Grifo de Lavanderia de 15cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_27.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '85',
      descripcion: ''
    },
    {
      nombre: 'Grifo de Pared Allure Pico Alto Móvil Blanco para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_7.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '16',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'
    },
    {
      nombre: 'Grifo de pared Allure Pico Alto Móvil Cromado para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_29.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '87',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'
    },
    {
      nombre: 'Grifo de Mesa Allure Pico Alto Móvil Blanco para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_8.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '17',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo de Mesa Allure Pico Alto Móvil Cromado para Cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_30.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '88',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo Allure Pico Móvil Blanco para Lavamanos',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_31.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '89',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'
    },
    {
      nombre: 'Grifo Allure Pico Móvil Cromado para Lavamanos',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_32.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '90',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'
    },
    {
      nombre: 'Grifo de Pared Allure Decore Pico Móvil para Cocina - Negro',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_9.png',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '18',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo de Pared Allure Decore Pico Móvil para Cocina - Ceniza',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_33.png',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '91',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo de Mesa Allure Decore Pico Móvil para Cocina - Negro',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_10.png',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '19',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo de Mesa Allure Decore Pico Móvil para Cocina - Ceniza',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_34.png',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '92',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo Allure Decore Pico Móvil para Lavamano - Negro',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_35.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '93',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo Allure Decore Pico Móvil para Lavamano - Ceniza',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_36.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '94',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo de Pared Fienza Pico Móvil para Cocina Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_37.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '95',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo de Pared Fienza Pico Móvil para Cocina Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_38.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '96',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo de Mesa Fienza Pico Móvil para Cocina Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_39.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '97',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo de mesa Fienza Pico Móvil para Cocina Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_40.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '98',
      descripcion: '<b>Funcionamiento:</b> Accionamiento 1/4 de vuelta, garantizando con menor esfuerzo el perfecto cierre del grifo. <br> <b>Aireador:</b> El aireador, además de garantizar un ahorro de agua del 70%, evita salpicaduras y proporciona comodidad para las manos con chorro suave. <br> <b>Caño goratorio:</b> Permite que el producto tenga rotación y pueda ser usado en el fregadero de dos cubas o para higienización del local, facilitando su manejo en el día a día.'

    },
    {
      nombre: 'Grifo Fienza Pico Móvil para Lavamano Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_41.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '99',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo Fienza Pico Móvil para Lavamano Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_42.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '100',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo de Pared Fienza Cristal Pico Móvil para Cocina Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_6.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '15',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo de Pared Fienza Cristal Pico Móvil para Cocina Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_43.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '101',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo de Mesa Fienza Cristal Pico Móvil para Cocina Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_5.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '14',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo de Mesa Fienza Cristal Pico Móvil para Cocina Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_44.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '102',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo Fienza Cristal Pico Alto para Lavamano Blanco',//
      foto: '../../assets/Fotos/Productos/Grifos/grifo_45.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '103',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo Fienza Cristal Pico Alto para Lavamano Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_46.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '104',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo. <br> <b>Aireador:</b> El aireador, además de garantizar un 70% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    /* {
      nombre: 'Grifo de lavanderia 10cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_14.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '20',
      descripcion: ''
    }, */
    /* {
      nombre: 'Grifo de lavanderia cromado 10cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_15.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '21',
      descripcion: ''
    }, */
    /* {
      nombre: 'Grifo sin pico de lavanderia blanco 10cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_16.jpg',
      id_categoria: ['2'],
      id_subcategoria: '',
      id_marca: '1',
      id : '22',
      descripcion: ''
    }, */
    {
      nombre: 'Grifo con Filtro Cromado',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_18.jpg',
      id_categoria: ['7'],
      id_subcategoria: '',
      id_marca: '1',
      id : '24',
      descripcion: 'Los Purificadores de Víqua aportan más salud, bienestar y calidad al hogar. Su triple sistema de filtración hace que el proceso de depuración sea eficaz. <br> <br> <b>Accionamiento:</b> Accionamiento rotativo con 3 juntas para garantizar una perfecta estanqueidad y un fácil manejo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'
    },
    {
      nombre: 'Grifo con Filtro Blanco',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_20.jpg',
      id_categoria: ['7'],
      id_subcategoria: '',
      id_marca: '1',
      id : '25',
      descripcion: 'Los Purificadores de Víqua aportan más salud, bienestar y calidad al hogar. Su triple sistema de filtración hace que el proceso de depuración sea eficaz. <br> <br> <b>Accionamiento:</b> Accionamiento rotativo con 3 juntas para garantizar una perfecta estanqueidad y un fácil manejo. <br> <b>Aireador:</b> El aireador, además de garantizar un 50% de ahorro de agua, evita salpicaduras y proporciona comodidad para las manos con un suave chorro. <br> <b>Caño giratorio:</b> Permite rotar el producto y utilizarlo en un fregadero de dos lavabos o para la limpieza del local, facilitando su manipulación diaria.'

    },
    {
      nombre: 'Grifo de Jardin',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_22.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '27',
      descripcion: 'La Grifería Víqua Garden tiene un diseño práctico y atemporal, lo que la hace perfecta para instalar en todos los ambientes. Con volante en cruz, garantiza firmeza y precisión a la hora de abrir y cerrar el producto. Fabricado en ABS, un plástico de ingeniería que tiene excelentes propiedades como alto brillo, durabilidad y resistencia al impacto. <br> <br> <b>Accionamiento:</b> Accionamiento rotativo con 3 juntas para garantizar una perfecta estanqueidad y un fácil manejo.'
    },
    {
      nombre: 'Ducha Electrica 4T',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_4.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '30',
      descripcion: 'La ducha eléctrica 4T es la más potente de la categoría, con hasta 7700W. La resistencia de la ducha está revestida de aluminio y cromo, lo que proporciona una mayor seguridad y evita la oxidación del producto.'
    },
    {
      nombre: 'Resistencia Ducha Electrica',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_5.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '31',
      descripcion: 'Resistencia revestida de aluminio y cromo, lo que proporciona una mayor seguridad y evita la oxidación del producto.'
    },
    {
      nombre: 'Ducha Higienica',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_1.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '28',
      descripcion: '<b>Activación:</b> Activación 1/4 de vuelta, asegurando un perfecto cierre del grifo con menor esfuerzo.'
    },{
      nombre: 'Ducha Fria',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_3.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '29',
      descripcion: 'La ducha fría Víqua está indicada para su instalación en baños o zonas exteriores que utilicen agua fría (sin instalación eléctrica para calentar agua).'
    },
    {
      nombre: 'Asiento para Inodoro',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_6.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '32',
      descripcion: 'La línea de Asientos Sanitarios Pratic es fácil de instalar y con calidad. Además de ser fácil de limpiar, tiene una apertura higiénica para evitar el contacto de las manos con el inhodoro. Con la tapa envolvente, el asiento amortigua el sonido de la descarga.'
    },
    {
      nombre: 'Kit de Baño',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_7.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '33',
      descripcion: 'Con un diseño moderno, la línea de accesorios de baño Atlantis está fabricada 100% en ABS, cuenta con un soporte desmontable que facilita la limpieza. Cuenta con una instalación fácil (viene con tarugos y tornillos).'
    },
    {
      nombre: 'Manguera Extra Fuerte',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-9.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '34',
      descripcion: ''
    },
    {
      nombre: 'Manguera Extra Topflex',
      foto: '../../assets/Fotos/Productos/Jardin/jardin-10.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '35',
      descripcion: ''
    },
    {
      nombre: 'Válvula de Pozo Roscable',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_8.jpg',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '36',
      descripcion: ''
    },
    {
      nombre: 'Flotador',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_9.jpg',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '37',
      descripcion: ''
    },
    /* {
      nombre: 'Teflon',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_10.jpg',
      id_categoria: ['3'],
      id_subcategoria: '',
      id_marca: '1',
      id : '38',
      descripcion: ''
    }, */
    {
      nombre: 'Válvula Compact de Esfera Roscable',
      foto: '../../assets/Fotos/Productos/Riego/riego_1.jpg',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '39',
      descripcion: ''
    },
    {
      nombre: 'Válvula Rosca Externa',
      foto: '../../assets/Fotos/Productos/Riego/riego_2.jpg',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '40',
      descripcion: ''
    },
    /* {
      nombre: 'Válvula rosca externa',
      foto: '../../assets/Fotos/Productos/Riego/riego_2.jpg',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '41',
      descripcion: ''
    }, */
    {
      nombre: 'Membrana Asfáltica Vitmanta Alumínio',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/impermeabilizante_3.jpg',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '42',
      descripcion: '<b>DESCRIPCIÓN</b><br> Membrana asfáltica de 1,30 m2 de área, considerando sobreexposiciones y perdidas por recortes de detalle.<br><br> <b>INDICADA PARA</b><br>Alumínio: Áreas residenciales expuestas sin circulación.<br><br> <b>BENEFÍCIOS:</b><br> • Alta resistência <br> • Alta durabilidad <br> • Alta adherencia<br><br><b>RENDIMENTO APROXIMADO</b><br> • Rinde hasta 10 m2'
    },
    {
      nombre: 'Membrana Asfaltica Líquida Vedalage Negro',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/vedalagePreto.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '43',
      descripcion: '<b>DESCRIPCIÓN</b><br> Vedalage® Black es una manta líquida lista para usar a base de asfalto modificado con polímeros, lista para usar. Es un agente impermeabilizante que presenta excelentes características de elasticidad, flexibilidad y adherencia.<br><br> <b>BENEFICIOS</b><br> • Listo para usar;<br> • Fácil manejo y aplicación;<br> • Alta resistencia, elasticidad y flexibilidad;<br> • A base de agua, no es agresivo con el medio ambiente y se puede aplicar en interiores;<br> • Aplicación en frío, formando una membrana continua y sin costuras;<br> • Gran resistencia y durabilidad.<br><br> <b>USO</b><br> • Cubrir losas;<br> • Áreas frías (baño, cocina, balcón, balcón y área de lavado);<br> • Muros de contención (lado de tierra);<br> • Jardineras y babero;<br> • Saunas y cámaras frigoríficas;<br> • Canalones de hormigón;<br> • Paredes y suelos.'
    },
    {
      nombre: 'Membrana Asfaltica Color Alumínio Viaflex Fita Autoadesiva',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/impermeabilizante_1.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '44',
      descripcion: '<b>DESCRIPCIÓN</b><br> Viaflex Tape es una cinta impermeable autoadhesiva compuesta por una capa de adhesivo asfáltico, cubierta con una lámina de aluminio flexible y protegida por una película plástica. Material de fácil aplicación que brinda una solución inmediata para reparaciones, fugas e infiltraciones. <br><br> <b>BENEFICIOS</b><br> Aplicación rápida y sencilla. Impermeable y resistente al agua. Buena resistencia a la luz solar. Flexible. Adhesión a la mayoría de materiales utilizados en la construcción civil, como hormigón, mortero, ladrillos, tejas, aluminio, etc. No se necesitan herramientas especiales para la aplicación. <br><br> <b>USO</b><br> Azulejos (metálicos: fibrocemento y hormigón); Tapajuntas y canaletas de metal; Protección de tubería de PVC expuesta a la intemperie; Conductos de ventilación y aire acondicionado; Ángulos, furgonetas, carrocerías de maletero; Tienda de campaña; Cierre de superposiciones en un sistema encubierto. <br><br> <b>Tamaños:</b> 10cm, 15cm, 20cm, 30cm, 45cm, 94cm',
    },
    {
      nombre: 'Membrana Asfaltica Color Teja Viaflex Fita Autoadesiva',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/impermeabilizante_2.png',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '45',
      descripcion: '<b>DESCRIPCIÓN</b><br> Viaflex Tape es una cinta impermeable autoadhesiva compuesta por una capa de adhesivo asfáltico, cubierta con una lámina de aluminio flexible y protegida por una película plástica. Material de fácil aplicación que brinda una solución inmediata para reparaciones, fugas e infiltraciones. <br><br> <b>BENEFICIOS</b><br> Aplicación rápida y sencilla. Impermeable y resistente al agua. Buena resistencia a la luz solar. Flexible. Adhesión a la mayoría de materiales utilizados en la construcción civil, como hormigón, mortero, ladrillos, tejas, aluminio, etc. No se necesitan herramientas especiales para la aplicación. <br><br> <b>USO</b><br> Azulejos (metálicos: fibrocemento y hormigón); Tapajuntas y canaletas de metal; Protección de tubería de PVC expuesta a la intemperie; Conductos de ventilación y aire acondicionado; Ángulos, furgonetas, carrocerías de maletero; Tienda de campaña; Cierre de superposiciones en un sistema encubierto. <br><br> <b>Tamaños:</b> 10cm, 15cm, 20cm, 30cm, 45cm, 94cm',
    },
    {
      nombre: 'Adaptador Rosca Externa',
      foto: '../../assets/Fotos/Productos/Riego/riego_6.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '46',
      descripcion: ''
    },
    {
      nombre: 'Adaptador Rosca Interna',
      foto: '../../assets/Fotos/Productos/Riego/riego_7.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '47',
      descripcion: ''
    },
    {
      nombre: 'Adaptador Reducción Rosca Externa',
      foto: '../../assets/Fotos/Productos/Riego/riego_8.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '48',
      descripcion: ''
    },
    {
      nombre: 'Codo Rosca Interna',
      foto: '../../assets/Fotos/Productos/Riego/riego_9.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '49',
      descripcion: ''
    },
    {
      nombre: 'Codo Union Doble',
      foto: '../../assets/Fotos/Productos/Riego/riego_10.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '50',
      descripcion: ''
    },
    {
      nombre: 'Union en T Rosca Interna',
      foto: '../../assets/Fotos/Productos/Riego/riego_11.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '51',
      descripcion: ''
    },
    {
      nombre: 'Union en T Triple',
      foto: '../../assets/Fotos/Productos/Riego/riego_12.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '52',
      descripcion: ''
    },
    {
      nombre: 'Union Interna',
      foto: '../../assets/Fotos/Productos/Riego/riego_13.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '53',
      descripcion: ''
    },
    {
      nombre: 'Union Reducción',
      foto: '../../assets/Fotos/Productos/Riego/riego_14.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '3',
      id : '54',
      descripcion: ''
    },
    /*{
      nombre: 'Grifo sin pico de lavanderia de 15cm',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_23.jpg',
      id_categoria: ['2','4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '55',
      descripcion: ''
    }, */
    {
      nombre: 'Economy-fit',
      foto: '../../assets/Fotos/Productos/Filtros/filtro_2.png',
      id_categoria: ['7'],
      id_subcategoria: '',
      id_marca: '1',
      id : '56',
      descripcion: 'Reemplaza 200 botellones de agua'
    },
    {
      nombre: 'Purificador Dual',
      foto: '../../assets/Fotos/Productos/Filtros/filtro_1.jpg',
      id_categoria: ['7'],
      id_subcategoria: '',
      id_marca: '1',
      id : '57',
      descripcion: ''
    },
    {
      nombre: 'Kit de Baño',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_38.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '58',
      descripcion: ''
    },
    {
      nombre: 'Botiquin de Baño',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_13.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '59',
      descripcion: ''
    },
    {
      nombre: 'Ducha Fria Blanca',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_14.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '60',
      descripcion: ''
    },
    {
      nombre: 'Ducha Fria Metalizada',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_15.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '61',
      descripcion: ''
    },
    {
      nombre: 'Sopapa para Lavamanos V8 Blanca',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_16.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '62',
      descripcion: ''
    },
    {
      nombre: 'Sopapa para Lavamanos V8 Metalizada',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_25.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '63',
      descripcion: ''
    },
    {
      nombre: 'Chicotillo Flexible',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_17.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '64',
      descripcion: ''
    },
    {
      nombre: 'Sifon Multiuso Simple Blanco',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_18.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '65',
      descripcion: ''
    },
    {
      nombre: 'Sifon Multiuso Simple Metalizado',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_19.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '66',
      descripcion: ''
    },
    {
      nombre: 'Sifon Multiuso Doble Blanco',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_20.png',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '67',
      descripcion: ''
    },
    {
      nombre: 'Sifon Multiuso Doble Metalizado',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_26.jpg',
      id_categoria: ['8'],
      id_subcategoria: '',
      id_marca: '1',
      id : '68',
      descripcion: ''
    },
    {
      nombre: 'Entrada de Maquina de Lavar',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_21.png',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '69',
      descripcion: ''
    },
    {
      nombre: 'Salida de Maquina de lavar Curvo 28mm',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_22.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '70',
      descripcion: ''
    },
    {
      nombre: 'Salida de Maquina de Lavar Recto 29mm',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_23.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '71',
      descripcion: ''
    },
    {
      nombre: 'Salida de Maquina de Lavar Recto 22mm',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_24.jpg',
      id_categoria: ['9'],
      id_subcategoria: '',
      id_marca: '1',
      id : '72',
      descripcion: ''
    },
    /* {
      nombre: 'Adaptador Reduccion Rosca Externa',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_27.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '73',
      descripcion: ''
    }, */
    /* {
      nombre: 'Adaptador Rosca Externa',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_28.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '74',
      descripcion: ''
    },
    {
      nombre: 'Adaptador Rosca Interna',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_29.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '75',
      descripcion: ''
    }, */
    /* {
      nombre: 'Codo Union Doble',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_30.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '76',
      descripcion: ''
    }, */
    {
      nombre: 'Kit de Accesorios para Manguera',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_32.jpg',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '77',
      descripcion: ''
    },
    {
      nombre: 'Hidropistola 7 Funciones',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_33.png',
      id_categoria: ['4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '78',
      descripcion: ''
    },
    /* {
      nombre: 'Union en T Rosca Interna',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_34.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '79',
      descripcion: ''
    },
    {
      nombre: 'Union en T Triple',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_35.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '80',
      descripcion: ''
    }, */
    /* {
      nombre: 'Union Interna',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_36.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '81',
      descripcion: ''
    }, */
    /* {
      nombre: 'Union Reduccion',
      foto: '../../assets/Fotos/Productos/Accesorios/accesorio_37.png',
      id_categoria: ['5'],
      id_subcategoria: '',
      id_marca: '1',
      id : '82',
      descripcion: ''
    }, */
    /* {
      nombre: 'Grifo de mesa con pico móvil para cocina',
      foto: '../../assets/Fotos/Productos/Grifos/grifo_25.jpg',
      id_categoria: ['2','4'],
      id_subcategoria: '',
      id_marca: '1',
      id : '83',
      descripcion: ''
    }, */
    {
      nombre: 'Ecoprimer',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/ecoprimer.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '105',
      descripcion: '<b>DESCRIPCIÓN:</b><br>Ecoprimer es una imprimación para la unión de tapetes y cintas asfálticas al agua compuestas por asfaltos modificados y aditivos sin disolventes, con bajo contenido en compuestos orgánicos volátiles (COV), para aplicación en frío sobre hormigón, mortero, mampostería y superficies de fibra de vidrio. <br><br> <b>BENEFICIOS</b> <br> • Listo para usar;<br> • Fácil manejo y aplicación;<br> • Buena adherencia y alta resistencia.;<br> • No agresivo con el medio ambiente, se puede aplicar en interiores..<br><br> <b>USO</b><br> Ecoprimer se puede aplicar sobre concreto, mortero, mampostería, superficies de fibra de vidrio, como imprimación para:<br> • Pegado de mantas asfalticas y cintas autoadhesivas;<br> • Soluciones de asfalto moldeadas en el lugar.'
    },
    {
      nombre: 'Contra Humedad',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/ContraUmidade.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '106',
      descripcion: '<b>DESCRIPCIÓN</b><br> Aditivo mineral impermeabilizante que mezclado con mortero u hormigón, proporciona impermeabilización, actuando por hidrofobicidad de capilares.<br><br> <b>BENEFICIOS</b><br> Aplicación sencilla y sencilla; No cambia el tiempo de curado del mortero u hormigón; Eficiencia permanente. No pierde sus características con el tiempo.<br><br> <b>USO</b><br> Contra Humedad es un agente impermeabilizante hidrófugo que reacciona con el cemento bloqueando los capilares de la estructura, interrumpiendo así el desarrollo de humedad en áreas por debajo del nivel del suelo, tales como:<br> • Piscinas subterráneas;<br> • Sótanos;<br> • Pozos de ascensor;<br> • Cimientos y zapatas;<br> • Muro de contención;<br> • Recobos externos;<br> • Tanque de agua enterrado.'
    },
    {
      nombre: 'Viaplus 1000',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viaplusMil.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '107',
      descripcion: '<b>DESCRIPCIÓN</b><br> Mortero impermeabilizante semiflexible, bicomponente (A+B), a base de cementos especiales, aditivos minerales y polímeros con excelentes características impermeabilizantes.<br><br> <b>ESPECIFICACIONES</b><br>• Temperatura mínima/máxima. de aplicación: 10°C a 35°C<br>• Intervalo mínimo entre capas: 2 a 6 horas<br><br> <b>BENEFICIOS</b><br>• Resistente a altas presiones hidrostáticas, tanto positivas como negativas.<br>• Soporta hasta 60 metros de columna de agua (m.c.a.).<br>• Exhibe una excelente adherencia y resistencia mecánica.<br>•No altera la potabilidad del agua, siendo atóxico e inodoro.<br>• Producto de aplicación sencilla, con brocha o cepillo de cerdas.<br>• Aplicable sobre superficies de concreto, albañilería y mortero.<br>• Permite el asentamiento directo de revestimientos sin necesidad de protección mecánica en el caso de baños, cocinas y lavanderías sin cota.<br><br><b>USO</b><br>• Impermeabilización de subsuelos, cortinas, pozos de ascensores<br>• Muros de contención de concreto, cimientos, zapatas, pilares, paredes internas y externas<br>• Áreas húmedas y susceptibles de humedad en contacto con el suelo, como baños, cocinas, lavanderías, áreas de servicio y tocadores<br>• Depósito de agua potable enterrado<br>• Sauna húmeda<br>• Estructuras sujetas a infiltración del nivel freático<br>• Sistema dual Viaplus 1000 y Viaplus 7000 o Viaplus 5000 estructurado con tela de poliéster Mantex para depósito de agua potable apoyado y elevado<br>• Sistema dual Viaplus 1000 y Viaplus 7000 para piscinas de concreto enterradas/espejos de agua enterrados'
    },
    {
      nombre: 'Viaplus 5000',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viapluscinmil.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '108',
      descripcion: '<b>DESCRIPCIÓN</b><br>Revestimiento impermeabilizante flexible, bicomponente a base de resinas elastoméricas y aditivos cementicios que, en su composición, dan como resultado una membrana polimérica modificada, con cemento de excelentes características de resistencia e impermeabilidad.<br><br> <b>ESPECIFICACIONES</b><br>• Temperatura mínima/máxima. de aplicación: 10°C a 35°C<br>• Intervalo mínimo entre capas: 4 a 8 horas<br><br> <b>BENEFICIOS</b><br>• Producto de fácil aplicación, con brocha o escoba de pelo.<br>• Excelente rendimiento de flexibilidad.<br>• Aplicado sobre superficies de hormigón, mampostería y mortero.<br>• No altera la potabilidad del agua, siendo atóxico e inodoro después del curado.<br>• Resistente a altas presiones hidrostáticas positivas.<br>• Sigue los movimientos estructurales y fisuras previstos en las Normas Brasileñas.<br><br><b>USO</b><br>Depósito de agua potable apoyado o elevado de hormigón. Utilizar como sistema dual Viaplus 1000 o Viaplus Top y Viaplus 5000 estructurado con tejido de poliéster Mantex.'
    },
    {
      nombre: 'Viafix',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viafix.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '109',
      descripcion: '<b>DESCRIPCIÓN</b><br>Emulsión adhesiva a base de resina especial (PVA) de alto rendimiento, compatible con cemento y cal, utilizada como promotor de adhesión para hormigón, morteros y losas, con excelentes propiedades de plasticidad.<br><br> <b>BENEFICIOS</b><br>• Proporciona una mayor adhesión a los morteros en varios sustratos, lo que permite su aplicación sobre hormigón liso <br>• Gran resistencia a la alcalinidad<br>• Permite una mejor conexión entre hormigones de diferentes edades y hormigones nuevos<br>• Proporciona morteros con gran plasticidad y resistencia mecánica al desgaste, impacto, permitiendo el acompañamiento de diferentes dilataciones de los materiales.'
    },
    {
      nombre: 'Vedalage Plus',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/vedalageplus.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '110',
      descripcion: '<b>DESCRIPCIÓN</b><br>Es una manta líquida de alto rendimiento lista para usar a base de resina acrílica pura, que ofrece alta elasticidad, flexibilidad y resistencia a la intemperie, además de la propiedad de reflejar la luz solar, brindando mayor confort térmico a los ambientes interiores.<br><br> <b>BENEFICIOS</b><br>• Resina acrílica pura no amarillea. <br>• Listo para usar y fácil de aplicar. <br>• Mayor rendimiento y durabilidad. <br>• Secado rápido <br>• Excelente adherencia al hormigón y mortero. <br>• Alta elasticidad y flexibilidad. <br>• Resistente a los rayos ultravioleta. <br><br> <b>USO</b><br> •Permite la colocación directa de revestimientos sin necesidad de protección mecánica. <br>• Losas de hormigón con o sin tráfico. <br>• marquesinas. <br>• Baldosas de fibrocemento. <br>• Bóvedas y cobertizos. <br>• vigas de hormigón. <br>• Canalones de hormigón o fibrocemento.'
    },
    {
      nombre: 'Viapol Sella Grietas',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/sellatrincas.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '111',
      descripcion: '<b>DESCRIPCIÓN</b><br>Viapol Sella Grietas es un sellador monocomponente flexible de base acrílica, desarrollado para tratamiento de grietas y fisuras en áreas internas y externas.<br><br> <b>BENEFICIOS</b><br>• Buena estabilidad de color. <br>• Buena adherencia <br>• Buena resistencia al intemperismo. <br>• Acepta pintura. <br>• Fácil aplicación. <br>• Compatible con pinturas a base de agua. <br>• Buena resistencia a los rayos UV. <br>• Para uso interno y externo. <br>• Baja emisión de compuestos orgánicos volátiles (V.O.C); <br><br> <b>USO</b><br>• Sellado o calafateo de grietas y fisuras internas y externas. <br>• Baja retracción. <br>• No reseca y no se agrieta. <br>• Sellado de brechas en paredes de Drywall. <br>• Grietas y fisuras en morteros de fachadas. <br>• Calafateo de fisuras verticales u horizontales de baja movimiento. <br>• Sellado de brechas entre aberturas de madera o metal.'
    },
    {
      nombre: 'Viaplus Reparo',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viaplusReparo.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '112',
      descripcion: '<b>DESCRIPCIÓN</b><br>Impermeabilizante monocomponente blanco, a base de cementos especiales, aditivos minerales y polímeros, indicado para sellado y tratamiento de humidad en paredes internas y externas.<br><br> <b>BENEFICIOS</b><br>• Resistente a la humedad ascendente. <br>• Aplicación directa sobre revoque íntegro y bloques de hormigón y cerámica. <br>• Fácil aplicación. <br>• Puede ser usado como terminación final. <br>• Acepta pintura a base de agua. <br><br> <b>USO</b><br>Viaplus® Reparo es utilizado para impermeabilización, eliminación de la humedad en paredes internas y externas.'
    },
    {
      nombre: 'ViaFoil',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viafoil.png',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '113',
      descripcion: '<b>DESCRIPCIÓN</b><br>Revestimiento base para tejados elaborado con film de polietileno y recubierto con film reflectante aluminio que proporciona una excelente protección impermeable y aislamiento térmico a las cubiertas de edificios.<br><br> <b>BENEFICIOS</b><br>• Proporciona un mecanismo de reflexión o retención del calor, reduciendo la temperatura de la zona en verano y manteniéndola caliente en invierno.<br>• Previene problemas indeseables de goteras e infiltraciones, generalmente provocados por el desgaste natural de las baldosas, fuertes vientos o problemas de pendientes.<br>• Inhibe la proliferación de hongos, insectos y roedores.<br>• Alta durabilidad;. <br><br> <b>USO</b><br>Tejados en General.'
    },
  ];

  private imagenes = [
    {
      image: '/assets/Fotos/Banners-menu/banner_1.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_1.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_2.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_2.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_3.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_3.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_4.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_4.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_5.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_5.png',
      alt: '',
      title: ''
    }
  ];

  private SUBCATEGORIAS = [{}];

  private MARCAS = [
    {
      nombre: 'Viqua',
      foto: '../../assets/Fotos/Marcas/viqua-logo.png',
      id: '1'
    },
    {
      nombre: 'Viapol',
      foto: '../../assets/Fotos/Marcas/viapol-logo.png',

      id: '2'
    },
    {
      nombre: 'Plasbohn',
      foto: '../../assets/Fotos/Marcas/plasbohn-logojpg.jpg',
      id: '3'
    }
  ];

  private DESTACADOS = [
    {
      nombre: 'Membrana Asfaltica Color Alumínio  Viaflex Fita Autoadesiva',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/impermeabilizante_1.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '44',
      descripcion: '<b>DESCRIPCIÓN</b><br> Viaflex Tape es una cinta impermeable autoadhesiva compuesta por una capa de adhesivo asfáltico, cubierta con una lámina de aluminio flexible y protegida por una película plástica. Material de fácil aplicación que brinda una solución inmediata para reparaciones, fugas e infiltraciones. <br><br> <b>BENEFICIOS</b><br> Aplicación rápida y sencilla. Impermeable y resistente al agua. Buena resistencia a la luz solar. Flexible. Adhesión a la mayoría de materiales utilizados en la construcción civil, como hormigón, mortero, ladrillos, tejas, aluminio, etc. No se necesitan herramientas especiales para la aplicación. <br><br> <b>USO</b><br> Azulejos (metálicos: fibrocemento y hormigón); Tapajuntas y canaletas de metal; Protección de tubería de PVC expuesta a la intemperie; Conductos de ventilación y aire acondicionado; Ángulos, furgonetas, carrocerías de maletero; Tienda de campaña; Cierre de superposiciones en un sistema encubierto. <br><br> <b>Tamaños:</b> 10cm, 15cm, 20cm, 30cm, 45cm, 94cm',
    },
    {
      nombre: 'Membrana Asfaltica Color Teja Viaflex Fita Autoadesiva',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/impermeabilizante_2.png',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '45',
      descripcion: '<b>DESCRIPCIÓN</b><br> Viaflex Tape es una cinta impermeable autoadhesiva compuesta por una capa de adhesivo asfáltico, cubierta con una lámina de aluminio flexible y protegida por una película plástica. Material de fácil aplicación que brinda una solución inmediata para reparaciones, fugas e infiltraciones. <br><br> <b>BENEFICIOS</b><br> Aplicación rápida y sencilla. Impermeable y resistente al agua. Buena resistencia a la luz solar. Flexible. Adhesión a la mayoría de materiales utilizados en la construcción civil, como hormigón, mortero, ladrillos, tejas, aluminio, etc. No se necesitan herramientas especiales para la aplicación. <br><br> <b>USO</b><br> Azulejos (metálicos: fibrocemento y hormigón); Tapajuntas y canaletas de metal; Protección de tubería de PVC expuesta a la intemperie; Conductos de ventilación y aire acondicionado; Ángulos, furgonetas, carrocerías de maletero; Tienda de campaña; Cierre de superposiciones en un sistema encubierto. <br><br> <b>Tamaños:</b> 10cm, 15cm, 20cm, 30cm, 45cm, 94cm',
    },
    {
      nombre: 'Ecoprimer',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/ecoprimer.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '105',
      descripcion: '<b>DESCRIPCIÓN:</b><br>Ecoprimer es una imprimación para la unión de tapetes y cintas asfálticas al agua compuestas por asfaltos modificados y aditivos sin disolventes, con bajo contenido en compuestos orgánicos volátiles (COV), para aplicación en frío sobre hormigón, mortero, mampostería y superficies de fibra de vidrio. <br><br> <b>BENEFICIOS</b> <br>• Listo para usar;<br> • Fácil manejo y aplicación;<br> • Buena adherencia y alta resistencia.;<br> • No agresivo con el medio ambiente, se puede aplicar en interiores..<br><br> <b>USO</b><br> Ecoprimer se puede aplicar sobre concreto, mortero, mampostería, superficies de fibra de vidrio, como imprimación para:<br> • Pegado de mantas asfalticas y cintas autoadhesivas;<br> • Soluciones de asfalto moldeadas en el lugar.'
    },
    {
      nombre: 'Viaplus 5000',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viapluscinmil.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '108',
      descripcion: '<b>DESCRIPCIÓN</b><br>Revestimiento impermeabilizante flexible, bicomponente a base de resinas elastoméricas y aditivos cementicios que, en su composición, dan como resultado una membrana polimérica modificada, con cemento de excelentes características de resistencia e impermeabilidad.<br><br> <b>ESPECIFICACIONES</b><br>• Temperatura mínima/máxima. de aplicación: 10°C a 35°C<br>• Intervalo mínimo entre capas: 4 a 8 horas<br><br> <b>BENEFICIOS</b><br>• Producto de fácil aplicación, con brocha o escoba de pelo.<br>• Excelente rendimiento de flexibilidad.<br>• Aplicado sobre superficies de hormigón, mampostería y mortero.<br>• No altera la potabilidad del agua, siendo atóxico e inodoro después del curado.<br>• Resistente a altas presiones hidrostáticas positivas.<br>• Sigue los movimientos estructurales y fisuras previstos en las Normas Brasileñas.<br><br><b>USO</b><br>Depósito de agua potable apoyado o elevado de hormigón. Utilizar como sistema dual Viaplus 1000 o Viaplus Top y Viaplus 5000 estructurado con tejido de poliéster Mantex.'
    },
    {
      nombre: 'Viaplus 1000',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/viaplusMil.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '107',
      descripcion: '<b>DESCRIPCIÓN</b><br> Mortero impermeabilizante semiflexible, bicomponente (A+B), a base de cementos especiales, aditivos minerales y polímeros con excelentes características impermeabilizantes.<br><br> <b>ESPECIFICACIONES</b><br>• Temperatura mínima/máxima. de aplicación: 10°C a 35°C<br>• Intervalo mínimo entre capas: 2 a 6 horas<br><br> <b>BENEFICIOS</b><br>• Resistente a altas presiones hidrostáticas, tanto positivas como negativas.<br>• Soporta hasta 60 metros de columna de agua (m.c.a.).<br>• Exhibe una excelente adherencia y resistencia mecánica.<br>•No altera la potabilidad del agua, siendo atóxico e inodoro.<br>• Producto de aplicación sencilla, con brocha o cepillo de cerdas.<br>• Aplicable sobre superficies de concreto, albañilería y mortero.<br>• Permite el asentamiento directo de revestimientos sin necesidad de protección mecánica en el caso de baños, cocinas y lavanderías sin cota.<br><br><b>USO</b><br>• Impermeabilización de subsuelos, cortinas, pozos de ascensores<br>• Muros de contención de concreto, cimientos, zapatas, pilares, paredes internas y externas<br>• Áreas húmedas y susceptibles de humedad en contacto con el suelo, como baños, cocinas, lavanderías, áreas de servicio y tocadores<br>• Depósito de agua potable enterrado<br>• Sauna húmeda<br>• Estructuras sujetas a infiltración del nivel freático<br>• Sistema dual Viaplus 1000 y Viaplus 7000 o Viaplus 5000 estructurado con tela de poliéster Mantex para depósito de agua potable apoyado y elevado<br>• Sistema dual Viaplus 1000 y Viaplus 7000 para piscinas de concreto enterradas/espejos de agua enterrados'
    },
    {
      nombre: 'Membrana Asfaltica Líquida Vedalage Negro',
      foto: '../../assets/Fotos/Productos/Impermeabilizantes/vedalagePreto.webp',
      id_categoria: ['1'],
      id_subcategoria: '',
      id_marca: '2',
      id : '43',
      descripcion: '<b>DESCRIPCIÓN</b><br> Vedalage® Black es una manta líquida lista para usar a base de asfalto modificado con polímeros, lista para usar. Es un agente impermeabilizante que presenta excelentes características de elasticidad, flexibilidad y adherencia.<br><br> <b>BENEFICIOS</b><br> • Listo para usar;<br> • Fácil manejo y aplicación;<br> • Alta resistencia, elasticidad y flexibilidad;<br> • A base de agua, no es agresivo con el medio ambiente y se puede aplicar en interiores;<br> • Aplicación en frío, formando una membrana continua y sin costuras;<br> • Gran resistencia y durabilidad.<br><br> <b>USO</b><br> • Cubrir losas;<br> • Áreas frías (baño, cocina, balcón, balcón y área de lavado);<br> • Muros de contención (lado de tierra);<br> • Jardineras y babero;<br> • Saunas y cámaras frigoríficas;<br> • Canalones de hormigón;<br> • Paredes y suelos.'
    },
    
  ];

  private CATALOGOS = [
    {
      nombre: 'Viapol',
      foto: '../../assets/Fotos/Marcas/viapol-logo.png',
      url: 'https://drive.google.com/file/d/13zUxLOWV0adFizthyMS-aq90nDFzk_Yj/view?usp=drive_link',
      id_marca: '2',
      id : '1',
    },
    {
      nombre: 'Viqua',
      foto: '../../assets/Fotos/Marcas/viqua-logo.png',
      url: 'https://drive.google.com/file/d/1fgSZNubdknrGtqautJtWqP2oGQNFF5_9/view?usp=drive_link',
      id_marca: '1',
      id : '2',
    },
    {
      nombre: 'Plasbohn',
      foto: '../../assets/Fotos/Marcas/plasbohn-logojpg.jpg',
      url: 'https://drive.google.com/file/d/1HwkkoWUirL4qOM2mg00lbW0uiL9DLoD2/view?usp=sharing',
      id_marca: '3',
      id : '3',
    }
  ];

  private IdCategoria;

  private IdProducto;

  private IdSubCategoria;

  private IdMarca;

  public filtrados: Producto[];

  public urlProductName = '';

  changeFiltrados = new BehaviorSubject<Producto[]>(this.PRODUCTOS);

  public producto: any = {};

  
  constructor() {
  }

  getProducto(){
    return this.producto;
  }

  setProducto(prod){
    this.producto = prod;
  }

  getIdCategoria(): string {
    return this.IdCategoria;
  }

  setIdCategoria(id): void {
    this.IdCategoria = id;
  }

  getIdProducto(): string {
    return this.IdProducto;
  }

  setIdProducto(id): void {
    this.IdProducto = id;
  }

  getIdSubCategoria(): string {
    return this.IdSubCategoria;
  }

  setIdSubCategoria(id): void {
    this.IdSubCategoria = id;
  }

  getIdMarca(): string {
    return this.IdMarca;
  }

  setIdMarca(id): void {
    this.IdMarca = id;
  }

  getCategorias(): any {
    return this.CATEGORIAS;
  }

  setCategorias(id): void {
    this.CATEGORIAS = id;
  }

  getCatalogues(): any {
    return this.CATALOGOS;
  }

  setCatalogues(): any {
    return this.CATALOGOS;
  }

  getDestacados(): any {
    return this.DESTACADOS;
  }

  setDestacados(id): void {
    this.DESTACADOS = id;
  }

  getProductos(): any[] {
    return this.PRODUCTOS;
  }

  setProductos(id): void {
    this.PRODUCTOS = id;
  }

  getSubCategorias(): any {
    return this.SUBCATEGORIAS;
  }

  setSubCategorias(id): void {
    this.SUBCATEGORIAS = id;
  }

  getMarcas(): any {
    return this.MARCAS;
  }

  setMarcas(id): void {
    this.MARCAS = id;
  }

  getProductosByIdCategoria(id): Producto[] {
    id = id+'';
    this.filtrados = this.PRODUCTOS.filter(x => (x.id_categoria.find(z => z === id) != undefined));
    return this.filtrados;
  }

  public filtrarByIdCategoria(id): void {
    id = id+'';
    let filtradoz = this.PRODUCTOS.filter(x => (x.id_categoria.find(z => z === id) != undefined));
    this.changeFiltrados.next(filtradoz);
  }

  concatObjectArray(array1, array2) {
    let newArray=[];
    array1.forEach(element => {
      newArray.push(element)
    });
    array2.forEach(element => {
      newArray.push(element)
    });
    return newArray;
  }

  public filtrarByNombre(name): void {
    name = name+'';
    let filtradosProd: Producto[];
    let filtradosProdCat: Producto[];

    filtradosProd = this.PRODUCTOS.filter(x => (x.nombre.toUpperCase().includes(name.toUpperCase()) === true));//filter by product name
    let categoriasFiltradas = this.CATEGORIAS.filter(x => (x.nombre.toUpperCase().includes(name.toUpperCase()) === true));//filter categories by name
    for(let i = 0; i < categoriasFiltradas.length; i++){
      filtradosProdCat = this.getProductosByIdCategoria(categoriasFiltradas[i].id);//filter productos by category name
    }
    if(filtradosProdCat !== undefined){
      filtradosProdCat = filtradosProdCat.filter(prod => (filtradosProd.find(filt => prod.id === filt.id) == undefined)) //don't add same products
      filtradosProd = this.concatObjectArray(filtradosProd, filtradosProdCat);
    }

    this.changeFiltrados.next(filtradosProd);
  }

  public getProductoByUrlName(urlname = this.urlProductName){
    let filtradosProd = [];
    let name = urlname.replace(/-/g, ' ');
    do{
      filtradosProd = this.PRODUCTOS.filter(x => (x.nombre.toUpperCase().includes(name.toUpperCase()) === true));//filter by product name
      if(filtradosProd.length === 0){
        name = this.eliminarUltimaPalabra(name);
      }
    }while(filtradosProd.length === 0);
    return filtradosProd[0];
  }

  public createRouteFromName(str){
    str = str.replace(/[\s()]+/g, '-');//delete spaces and parentesis
    if(str.substr(str.length - 1) === '-'){
      str = str.substring(0, str.length - 1);
    }
    this.urlProductName = str;
    return str;
  }

  public eliminarUltimaPalabra(str: string): string {
    // Dividir el string en un array de palabras
    const palabras = str.split(' ');

    // Eliminar la última palabra del array
    palabras.pop();

    // Unir las palabras restantes para formar el nuevo string
    const nuevoString = palabras.join(' ');

    return nuevoString;
  }

  public obtenerPrimeras3Palabra(str: string): string {
    // Dividir el string en un array de palabras
    const palabras = str.split(' ');
    const primeras3Palabras = palabras.slice(0, 3);

    // Unir las palabras restantes para formar el nuevo string
    const nuevoString = primeras3Palabras.join(' ');

    return nuevoString;
  }

  shuffleArray(array){
    for (let i = array.length - 1; i > 0; i--) { 
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array;
  }

  public getProductosRelacionadosByProducto(producto: Producto){
    //categoria, nombre
    debugger;
    let prodNombre: Producto[];
    let prodCategoria: Producto[];
    let relacionados: Producto[];
    prodCategoria = this.PRODUCTOS.filter(x => (x.id_categoria.some(item => producto.id_categoria.includes(item)) && x.id != producto.id));
    let shuffledProdCategoria = this.shuffleArray(prodCategoria);
    prodNombre = shuffledProdCategoria.filter(x => (x.nombre.toUpperCase().includes(this.obtenerPrimeras3Palabra(producto.nombre.toUpperCase())) === true && x.id != producto.id));
    if(prodNombre.length >= 3){
      relacionados = prodNombre.slice(0, 3);
    } else {
      prodCategoria = [...prodNombre, ...prodCategoria];
      if(prodCategoria.length >= 3){
        relacionados = prodCategoria.slice(0, 3);
      }
    }
    return relacionados;
    //array.slice(0, n);
  }

  /* changeFiltradosDo(id) {
    this.changeFiltrados.next(this.getProductosByIdCategoria(id));
  } */

  /* getProductosByIdName(id): Producto[] {
    id = id+'';
    let filtrado: Producto[];
    filtrado = this.PRODUCTOS.filter(x => (x.nombre.find(z => z === id) != undefined));
    return filtrado;
  } */
}

export interface Producto {
  nombre: string,
  foto: string,
  id_categoria: string[],
  id_subcategoria: string,
  id_marca: string, 
  id: string,
  descripcion: string
};
