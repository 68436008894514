<!-- <div class="mat-dialog-container"> -->
<mat-dialog-content class="mat-dialog-container">
    <!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="black">close</mat-icon>
    </button> -->
    <button type="button" class="btn btn-outline-dark close-button" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="row center">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 center animated fadeIn" style="background-color: white;">
            <img src="{{ producto.foto }}" class="mx-auto" alt="" style="max-width: 100%; max-height: 100%; width: 400px; height: 400px;">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 animated fadeIn">
            <!-- <span>{{ producto.nombre }}</span> -->
            <h3 class="letraviqua"><b>{{ producto.nombre }}</b></h3>
            <div class="">
                <span class="letranegra" [innerHTML]="producto.descripcion">
                    
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<!-- </div> -->