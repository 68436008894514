import { Component, Input } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bienvenido a la pagina oficial de';
  public imagenes = [
    {
      image: '/assets/Fotos/Banners-menu/banner_1.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_1.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_2.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_2.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_3.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_3.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_4.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_4.png',
      alt: '',
      title: ''
    },
    {
      image: '/assets/Fotos/Banners-menu/banner_5.png',
      thumbImage: '/assets/Fotos/Banners-menu/banner_5.png',
      alt: '',
      title: ''
    }
  ];
  public openMenu: boolean = false;
  isOver = false;

  clickMenu(){
    this.openMenu = !this.openMenu;
  }
}
