<body>
    <!--     <ng-image-slider class="d-flex" [animationSpeed]="1" [imagePopup]="false" [infinite]="true" [images]="imagenes" [imageSize]="{width: '100%', height: '80%', space: 1}" [infinite]="true" [autoSlide]="{interval: 4, stopOnHover: false}"></ng-image-slider>
 -->
    <div class="container">
        <div class="row m-1">
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="..." style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">GRIFOS</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="..." style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">IMPERMEABILIZANTE</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="../../../assets/Fotos/Productos/jardin.jpeg" style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">JARDIN</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-1">
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="..." style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">FILTROS</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="..." style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">RIEGO</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card m-1" style="width: 18rem;">
                    <img src="..." style="width: 270px; height: 270px;" class="card-img-top mx-auto" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">ACCESORIOS</h5>
                        <p class="card-text"></p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>