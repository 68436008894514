import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { NgImageSliderModule } from 'ng-image-slider';
import { FormsModule } from '@angular/forms';

//servicios
import { CompartidosService } from './services/compartidos.service';

//rutas
import { AppRoutingModule } from './app-routing.module';


//components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BodyComponent } from './components/body/body.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductosComponent } from './pages/productos/productos.component';

import { CommonModule } from '@angular/common';  
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { AboutComponent } from './pages/about/about.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { DetalleComponent } from './components/detalle/detalle.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    HomeComponent,
    ProductosComponent,
    AboutComponent,
    DetalleComponent
  ],
  imports: [
    BrowserModule,
    //NgImageSliderModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    CommonModule
  ],
  providers: [
    CompartidosService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
