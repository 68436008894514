<div class="container">
    <!-- <h1>single-product</h1> -->
    <div class="my-5 mx-3">
    <!--  <ng-container  *ngFor="let product of product_list"> -->
        <!-- <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 vertical-center centrarLetras">
                <h1 class="letraviqua">{{product.nombre | slice:0:100}}</h1>
            </div>
        </div> -->
        <div class="row my-2" *ngIf="product !== undefined">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 vertical-center">
                <img src={{product.foto}} width="100%" height="auto">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 vertical-center">
                <div class="">
                <h1 class="letraviqua mb-5">{{product.nombre | slice:0:100}}</h1>
                <span class="letranegra" [innerHTML]="product.descripcion">
                </span>
                </div>
            </div>
            
                
                <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <h3 class="text-center text-uppercase border border-warning">Reviews</h3>  
                </div> -->
        </div>
        <div class="row my-2">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 centrar my-2">
                <button (click)="buyThis()" class=" mx-auto btn btn-success shadow-lg rounded" >
                    <strong>Comprar ahora</strong>
                <img src="../../../assets/icons/whatsapp.png" class="bordeIcon" style="width: 20px; height: 20px; margin: 5px;">

                </button>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            </div>
        </div>
        <br>
        <hr>
        <br>

        <div class="row m-1">
            <h3><b>Productos Relacionados</b></h3>

                <div *ngFor="let producto of relacionados"  class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 animated mt-4">
                    <div class="card m-1 pointer mx-auto" (click)="openDialog(producto)" data-bs-toggle="tooltip" data-bs-placement="right" title="" style="width: 18rem; height: 16rem;">
                        <img src="{{ producto.foto }}" style="width: 150px; height: 150px;" class="card-img-top mx-auto" alt="...">
                        <div class="card-body">
                            <h5 class="card-title letraviqua centrarLetras"><b>{{ producto.nombre }}</b></h5>
                            <!-- <p class="card-text"></p>
                                <a href="" routerLink="/productos" class="btn btn-primary">Go somewhere</a> -->
                        </div>
                    </div>
                </div>
        </div>

    </div>
        
</div>