<!--The content below is only a placeholder and can be replaced.-->

<!-- <div style="text-align: start">
    <img width="150" alt="" src="../assets/Fotos/logo.png">
</div>

<body>
    <ng-image-slider style="padding-top: 20%;" [infinite]="true" [images]="imagenes" [infinite]="true" [imageSize]="{width: '100%', height: '100%', space: 3}" [autoSlide]="{interval: 4, stopOnHover: false}"></ng-image-slider>
</body>
<footer></footer> -->

<div class="app-wrapper">
    <app-header class="fixed_header"></app-header>
    <div class="container-fluid">
        <!-- fondo div img aqui -->
        <router-outlet class=""></router-outlet>
    </div>
    <div class="example-button-container">
        <button mat-fab class="floating-button" aria-label="WhatsApp Button">
            <a href="https://wa.me/59171635250" target="_blank">
                <img src="../../../assets/icons/whatsapp.png" alt="WhatsApp">
            </a>
        </button>
    </div>
    <!-- <div class="container-fluid">
        <app-body [imagenes]="imagenes"></app-body>
    </div> -->
    <app-footer class=""></app-footer>
</div>