<div class="container">
    <!-- mision vision -->
    <div class="container fadeIn m-1" style="margin-bottom: 30px;">
        <div class="row m-1" style="font-size: 20px; letter-spacing: 0.5px; line-height: 35px; padding: 10px">
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <!-- <h2>Fotos</h2>
                <div class="fondoGris">
                    <span class="letrablanca">
                        Foto.jpg
                    </span>
                </div> -->
                <img src="../../../assets/Fotos/about1.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px;">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <h2 style="color: teal;">¿Quienes somos?</h2>
                <div class="">
                    <span class="">
                        Somos una empresa boliviana con sede en Santa Cruz de la Sierra, fundada en el año 2008. 
                        Nos especializamos en la importación y distribución de productos principalmente brasileños de la más alta calidad. 
                        La misión como empresa, nos compromete a proporcionar a nuestros clientes una oferta eficiente, garantizando productos de excelencia a precios accesibles. 
                        Aspiramos a extender esta calidad a lo largo de todo el territorio boliviano, consolidándonos como referentes en la entrega de productos con una excelente calidad y precio.
                    </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <h2 style="color: teal;">Visión</h2>
                <div class="">
                    <span class="">
                        "Ser una empresa de alcance nacional, importando productos y soluciones oportunas, 
                        con la mejor relación calidad-precio"
                    </span>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <!-- <h2>Fotos</h2>
                <div class="">
                    <span class="">
                        Foto.jpg
                    </span>
                </div> -->
                <img src="../../../assets/Fotos/about2.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <!-- <h2>Fotos</h2>
                <div class="">
                    <span class="">
                        Foto.jpg
                    </span>
                </div> -->
                <img src="../../../assets/Fotos/about3.jpg" alt="" style="max-width: 100%; max-height: 100%; border-radius: 25px">
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-md-6 col-lg-6 col-xl-6" style="padding: 10px;">
                <h2 style="color: teal;">Misión</h2>
                <div class="">
                    <span class="">
                        "Aportar a la sociedad boliviana productos de alta calidad, a un precio accesible, 
                        ofreciendo la distribucion de manera rápida, oportuna y responsable"
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>