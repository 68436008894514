<div class="container mt-5">
    <h2 class="text-center mb-4">Locaciones por Ciudad</h2>
    <div class="row">
        <div class="col-md-4" *ngFor="let ciudad of ciudades">
            <div class="card mb-4 shadow-sm">
                <div class="card-body">
                    <h5 class="card-title d-flex align-items-center justify-content-between">
                        {{ ciudad.nombre }}
                        <img src="{{ ciudad.bandera }}" class="flag-icon" alt="{{ ciudad.nombre }} Bandera">
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let locacion of ciudad.locaciones">
                            <a href="{{locacion.ubicacion}}" class="custom-link" target="_blank">{{ locacion.nombre }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>