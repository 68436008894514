import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CompartidosService } from '../../services/compartidos.service';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { DetalleComponent } from '../../components/detalle/detalle.component';
import { Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @Input() imagenes: object;
  public categorias: [any];
  public destacados: [any];
  public marcas: [any];

  constructor(
    private compartidos_service: CompartidosService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private meta: Meta
    ) { 
      this.meta.updateTag({ name: 'Title', content: 'Importadora JV ImportadoraJV' });
      this.meta.updateTag({ name: 'description', content: 'Somos una empresa boliviana con sede en Santa Cruz de la Sierra, fundada en el año 2008. Nos especializamos en la importación y distribución de productos principalmente brasileños de la más alta calidad' });
      this.meta.updateTag({ name: 'keywords', content: 'impermeabilizante viapol viqua plasbohn membranas goteras grifos Bolivia Santa Cruz' });
      this.meta.updateTag({ name: 'lang', content: 'es' });

    }

  ngOnInit(): void {
    this.categorias = this.compartidos_service.getCategorias();
    this.destacados = this.compartidos_service.getDestacados();
    this.marcas = this.compartidos_service.getMarcas();
    console.log(this.categorias);
  }

  setCategoria(id){
    console.log(id);
    this.compartidos_service.setIdCategoria(id);
    this.compartidos_service.filtrarByIdCategoria(id);
    //this.compartidos_service.getProductosByIdCategoria(id);
    // console.log(this.compartidos_service.filtrados);
    this.router.navigate(['/productos']);
  }

  public openDialog(producto) {
    this.compartidos_service.setProducto(producto);
    let nombreRuta = this.compartidos_service.createRouteFromName(producto.nombre);
    this.router.navigate(["producto", nombreRuta]);

    /* this.dialog.open(DetalleComponent, {
      width: '900px',
      panelClass:'custom-dialog',
      hasBackdrop: true,
      closeOnNavigation: true
      // backdropClass: 'bdrop'
    }); */
  }

}
